<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>商品管理</template>
      <template v-slot:secondMenu>商品评论</template>
    </breadcrumb-nav>
    <el-card class="box-card goods-add" shadow="never">
      <el-button type="text" icon="el-icon-back" @click="$router.back(-1)">返回列表</el-button>
      <el-divider direction="vertical"></el-divider>
      <label>商品名称: {{ goodsName }}</label>
      <el-tabs style="margin-top: 20px" v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="规格列表" name="sku">
          <goods-sku-pane :goods-id="goodsId" v-if="tabRefresh.sku"></goods-sku-pane>
        </el-tab-pane>
        <el-tab-pane label="评论列表" name="comment">
          <goods-comment-pane :goods-id="goodsId" v-if="tabRefresh.comment"></goods-comment-pane>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import GoodsSkuPane from './GoodsSkuPane'
import GoodsCommentPane from './GoodsCommentPane'
import { getGoodsDetail } from '../../../network/goods'

export default {
  name: 'GoodsSku',
  components: {
    BreadcrumbNav,
    GoodsSkuPane,
    GoodsCommentPane
  },
  filters: {
    formatFloat (value) {
      return value.toFixed(2)
    }
  },
  data () {
    return {
      goodsName: '',
      goodsId: 0,
      activeName: 'sku',
      tabRefresh: {
        sku: true,
        comment: false
      }
    }
  },
  created () {
    this.goodsId = parseInt(this.$route.query.goodsId)
    this.getGoodsDetail(this.goodsId)
  },
  methods: {
    getGoodsDetail (goodsId) {
      getGoodsDetail(goodsId).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取商品信息失败', 'error')
        }
        this.goodsName = result.data.base.name
      })
    },
    commentGoods (row) {
    },
    handleTabClick (tab) {
      this.activeName = tab.name
      this.switchTab(this.activeName)
    },
    switchTab (tab) {
      for (const key in this.tabRefresh) {
        if (key === tab) {
          this.tabRefresh[key] = true
        } else {
          this.tabRefresh[key] = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
