<template>
  <div>
    <el-table
      border
      :data="list"
      style="width: 100%;"
    >
      <el-table-column type="index" label="序号" width="80px" align="center">
      </el-table-column>
      <el-table-column prop="logo" label="头像" width="120px">
        <template slot-scope="scope">
          <el-image
            style="height: 50px; width: 50px"
            :src="scope.row.imageUrl"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="nick" label="昵称" width="120px">
      </el-table-column>
      <el-table-column prop="star" label="评论分数" width="180px">
        <template slot-scope="scope">
          <el-rate
            v-model=scope.row.star
            disabled
          >
          </el-rate>
        </template>
      </el-table-column>
      <el-table-column
        prop="skuDesc"
        label="规格信息"
      ></el-table-column>
      <el-table-column
        prop="comment"
        label="评论内容"
      ></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.page"
      :page-sizes="[10, 20]"
      :page-size="listQuery.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { listGoodsComment } from '../../../network/goods'

export default {
  name: 'GoodsCommentPane',
  props: {
    goodsId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      list: [],
      downloadUrlPrefix: '',
      total: 0,
      listQuery: {
        page: 1,
        size: 10,
        type: 'all',
        goodsId: 0
      }
    }
  },
  mounted () {
    this.downloadUrlPrefix = window.g.MEDIA_DOWNLOAD_URL
    this.listQuery.goodsId = this.goodsId
    this.getGoodsComment()
  },
  methods: {
    getGoodsComment () {
      listGoodsComment(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取商品信息失败', 'error')
        }
        this.total = result.data.total
        this.list = result.data.list
        for (const item of this.list) {
          if (item.avatar.includes('http')) {
            item.imageUrl = item.avatar
          } else {
            item.imageUrl = this.downloadUrlPrefix + item.avatar
          }
        }
      })
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getGoodsComment()
    },
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getGoodsComment()
    }
  }
}
</script>

<style scoped>

</style>
