<template>
<div>
  <el-table
    border
    :data="skuList"
    style="width: 100%;"
  >
    <el-table-column type="index" label="序号" width="80px" align="center">
    </el-table-column>
    <el-table-column prop="logo" label="商品图标">
      <template slot-scope="scope">
        <el-image
          style="height: 50px; width: 50px"
          :src="scope.row.imageUrl"
        ></el-image>
      </template>
    </el-table-column>
    <el-table-column prop="skuDesc" label="规格信息">
    </el-table-column>
    <el-table-column
      prop="stock"
      label="库存"
    ></el-table-column>
    <el-table-column
      prop="sales"
      label="销量"
    ></el-table-column>
    <el-table-column
      prop="price"
      label="销售价"
    ></el-table-column>
    <el-table-column
      prop="supplyPrice"
      label="成本价"
    ></el-table-column>
    <el-table-column
      prop="marketPrice"
      label="市场建议价"
    ></el-table-column>
    <el-table-column prop="rate" label="利润率">
      <template slot-scope="scope">
        {{ scope.row.rate | formatFloat }}
      </template>
    </el-table-column>
    <el-table-column prop="salesRate" label="销售利润率">
      <template slot-scope="scope">
        {{ scope.row.salesRate | formatFloat }}
      </template>
    </el-table-column>
    <el-table-column label="操作" width="120px">
      <template slot-scope="scope">
        <el-row>
          <el-button type="success" icon="el-icon-s-comment" size="mini" @click="commentGoods(scope.row)">评论</el-button>
        </el-row>
      </template>
    </el-table-column>
  </el-table>
</div>
</template>

<script>
import { getGoodsDetail } from '../../../network/goods'

export default {
  name: 'GoodsSkuPane',
  props: {
    goodsId: {
      type: Number,
      default: 0
    }
  },
  filters: {
    formatFloat (value) {
      return value.toFixed(2)
    }
  },
  data () {
    return {
      attrs: [],
      skuList: [],
      goodsDetail: {},
      base: '',
      downloadUrlPrefix: ''
    }
  },
  mounted () {
    this.downloadUrlPrefix = window.g.MEDIA_DOWNLOAD_URL
    // this.goodsId = parseInt(this.$route.query.goodsId)
    this.getGoodsDetail(this.goodsId)
  },
  methods: {
    getGoodsDetail (goodsId) {
      getGoodsDetail(goodsId).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取商品信息失败', 'error')
        }
        this.goodsDetail = result.data
        this.base = this.goodsDetail.base
        this.attrs = this.goodsDetail.specs.specs.attrs
        this.skuList = []
        for (const item of this.goodsDetail.specs.skuList) {
          const skuItem = {
            price: item.price,
            oldPrice: item.oldPrice,
            stock: item.stock,
            sales: item.sales,
            marketPrice: item.marketPrice,
            salesRate: item.salesRate,
            rate: item.rate,
            supplyPrice: item.supplyPrice,
            skuId: item.skuId,
            goodsId: this.goodsId
          }
          if (item.icon.includes('http')) {
            skuItem.imageUrl = item.icon
          } else {
            skuItem.imageUrl = this.downloadUrlPrefix + item.icon
          }
          if (this.attrs.length <= 0 || this.attrs[0] === '') {
            this.attrs = []
            for (const attr in item.attrs) {
              this.attrs.push(attr)
            }
          }
          var skuDesc = []
          for (const attr of this.attrs) {
            skuItem[attr] = item.attrs[attr]
            skuDesc.push(skuItem[attr])
          }
          skuItem.skuDesc = skuDesc.toString()
          this.skuList.push(skuItem)
        }
      })
    },
    commentGoods (row) {
      this.$router.push({
        path: '/commentSubmit',
        query: { goodsId: row.goodsId, skuId: row.skuId }
      })
    }
  }
}
</script>

<style scoped>

</style>
